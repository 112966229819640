footer.footer {
  background-color: var(--footer-background);
  color: var(--footer-font-color);
  font-size: calc(15 / var(--rem-base) * 1rem);
  line-height: var(--footer-line-height);
  padding: 1.5rem;
}

.footer p {
  margin: 0.5rem 0;
}

.footer a {
  color: var(--footer-link-font-color);
}
